import {createApp} from 'vue'
import App from './App.vue'
import {routerWithStore} from './router'
import {store} from '@/store/store'
import Quasar from 'quasar/src/vue-plugin.js';
import {QuasarPluginOptions} from 'quasar/dist/types'
import 'core-js/stable' // iOS 11 support

import './styles/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import i18n from './i18n'

export const quasarConfig: Partial<QuasarPluginOptions> = {
  plugins: {},
  config: {
    // primary_default: '#00574F',
    // secondary_default: '#16A398'
  }
}

const app = createApp(App)
  .use(i18n)
  .use(Quasar, quasarConfig)

const router = routerWithStore(store)

app.use(router)
app.use(store)
app.mount('#app')
