import {createRouter, createWebHistory, RouteLocationRaw, Router} from 'vue-router'
import {Store} from '@/store/store'
import {routes} from '@/router/routes'
import {IUserDto} from '@/types/dtos/IUserDto'

export function routerWithStore(store: Store): Router {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  // Before each route, check for "guest only" and "auth only"
  router.beforeEach(async (to) => {
    const location = router.resolve(to)
    
    if ((!location || !location.name) && !location.href.startsWith('/form')) {
      window.location.href = '/form' + to.path
      return false
    }
    
    const isAuthenticated = store.authenticationState.isAuthenticated()
    
    if (isAuthenticated && !store.authenticationState.isCurrentUserLoaded())
      await store.authenticationState.fetchAuthenticatedUser()

    if (to.path === '' || to.path === '/' || to.meta.requiresGuest && isAuthenticated) {
      return getRouteForAuthenticatedUser(store.authenticationState.authenticatedUser)
    }

    if (!to.meta.requiresAuth) {
      return true
    }

    if (to.meta.requiresAuth && isAuthenticated) {
      return true
    } else {
      if (to.path.startsWith('/form/')) {
        return {
          name: 'PreTaxFormLogin', params: {formUrl: to.params.formUrl}
        }
      } else {
        window.location.href = '/authentication/log-in'
      }
      
      return false
    }
  })

  return router
}

function getRouteForAuthenticatedUser(authenticatedUser: IUserDto): RouteLocationRaw {
  // Redirect based on user role 
  if (authenticatedUser.roles.indexOf('SuperAdmin') > -1) {
    return {
      name: 'OrganizationsDashboard'
    }
  } else if (authenticatedUser.roles.indexOf('OrgAdmin') > -1 || authenticatedUser.roles.indexOf('Pro') > -1) {
    return {
      name: 'FilledFormsDashboard'
    }
  } else {
    return {
      name: 'ViewUser', params: {id: authenticatedUser.id}
    }
  }
}
