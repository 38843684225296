import {StateBase} from '@/store/types/StateBase'
import {Message} from '@/types/Message'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import axios from 'axios'
import {IFormDto} from '@/types/dtos/form/IFormDto'
import {IOrganizationDto} from '@/types/dtos/IOrganizationDto'
import {IGetFormForUrlApiResponse} from '@/types/responses/IGetFormForUrlApiResponse'
import getCssVar from 'quasar/src/utils/css-var/get-css-var.js';import setCssVar from 'quasar/src/utils/css-var/set-css-var.js';
import {ISectionDto} from '@/types/dtos/form/ISectionDto'
import {RouteLocationRaw} from 'vue-router'
import {useJwtUtils} from '@/composition/useJwtUtils'

export class FormsState extends StateBase {
  availableYears: number[]
  availableProvinces: string[]
  fetched: IFormDto[]
  current: IFormDto | undefined
  currentSection: ISectionDto | undefined
  organization: IOrganizationDto | undefined

  constructor(messages: Message[]) {
    super(messages, 'forms')
    this.availableYears = []
    this.availableProvinces = []
    this.fetched = []
    this.current = undefined
    this.currentSection = undefined
    this.organization = undefined
  }

  logOut(): void {
    this.availableYears = []
    this.availableProvinces = []
    this.fetched = []
    this.current = undefined
    this.currentSection = undefined
    this.organization = undefined

    this.updateCssColors()
  }
  
  async fetchAvailableYears(): Promise<boolean> {
    try {
      const response = await axios.get<number[]>(`${this.serviceRoot}/available-years`, {
        headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
      })

      this.availableYears = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.availableYears = []
      return false
    }
  }
  
  async fetchAvailableProvinces(year: number): Promise<boolean> {
    try {
      const response = await axios.get<string[]>(`${this.serviceRoot}/available-provinces/${year}`, {
        headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
      })

      this.availableProvinces = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.availableProvinces = []
      return false
    }
  }

  async fetch(): Promise<boolean> {
    try {
      const response = await axios.get<IFormDto[]>(this.serviceRoot, {
        headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
      })

      this.fetched = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined
      return false
    }
  }
  
  setSectionByOrder(order: number): boolean {
    if (!this.current || order < 0 || order >= this.current.sections.length)
      return false

    this.currentSection = this.current.sections.find(s => s.order === order)

    return this.currentSection != undefined
  }
  
  isLastSection(section: ISectionDto): boolean {
    if (!this.current)
      return false
    
    return this.current.sections.find(s => s.order > section.order) === undefined
  }

  getNextSectionRoute(): RouteLocationRaw | undefined {
    if (!this.current)
      return undefined

    const nextSectionOrder = this.currentSection
      ? this.currentSection.order + 1
      : 0
    
    if (nextSectionOrder > this.current.sections.length)
      return {
        name: 'PreTaxFormEnd'
      }

    const nextSection = this.current.sections.find(s => s.order == nextSectionOrder)

    if (!nextSection)
      return undefined

    if (nextSection.isChildrenOnly) {
      return {
        name: 'PreTaxFormSection', params: {order: nextSection.order + 1, childIndex: 1}
      }
    } else {
      return {
        name: 'PreTaxFormSection', params: {order: nextSection.order + 1}
      }
    }
  }

  getPreviousSectionRoute(totalChildren: number): RouteLocationRaw | undefined {
    if (!this.current)
      return undefined

    const previousSectionOrder = this.currentSection
      ? this.currentSection.order - 1
      : 0
    
    const previousSection = this.current.sections.find(s => s.order == previousSectionOrder)
    
    if (!previousSection)
      return undefined
    
    // Previous is for children, but user has none
    if (previousSection.isChildrenOnly && totalChildren == 0) {
      return {
        name: 'PreTaxFormSection', params: {order: previousSection.order}
      }
    }

    if (previousSection.isChildrenOnly) {
      return {
        name: 'PreTaxFormSection', params: {order: previousSection.order + 1, childIndex: totalChildren}
      }
    } else {
      return {
        name: 'PreTaxFormSection', params: {order: previousSection.order + 1}
      }
    }
  }
  
  async fetchAndSetCurrentForOrganization(id: string, organizationId: string): Promise<boolean> {
    try {
      const response = await axios.get<IFormDto>(
        this.serviceRoot + '/' + id + '/for-organization/' + organizationId,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      this.current = response.data

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined
      return false
    }
  }

  async fetchAndSetCurrentForUrl(formUrl: string, fiscalYear: number): Promise<boolean> {
    try {
      const response = await axios.get<IGetFormForUrlApiResponse>(
        `${this.serviceRoot}/for-url/${formUrl}/${fiscalYear}`,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})
      
      this.current = response.data.form
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined

      return false
    }
  }

  /**
   * Updates Quasar's CSS variables based on the current organization's settings.
   */
  updateCssColors(): void {
    const primaryColor = this.organization &&
    this.organization.primaryColor &&
    this.organization.primaryColor.length == 7
      ? this.organization.primaryColor
      : getCssVar('primary') as string

    const secondaryColor = this.organization &&
    this.organization.secondaryColor &&
    this.organization.secondaryColor.length == 7
      ? this.organization.secondaryColor
      : getCssVar('secondary') as string

    setCssVar('primary', primaryColor)
    setCssVar('accent', secondaryColor)
    setCssVar('secondary', secondaryColor)
  }
}
